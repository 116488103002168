import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react'
import { Global, css } from '@emotion/core'
import { Box } from 'reflexbox'
import axios from 'axios'
import { AES, enc } from 'crypto-js'
import { inflate } from 'pako'

const SLEEP_LIMIT = 1000 * 60 * 3

const Loading = ({ progress }) => (
  <Box
    style={{
      position: 'absolute',
      width: '100%',
      height: '3px',
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    }}
  >
    <Box
      width={`${progress}%`}
      style={{ backgroundColor: '#ff0000', height: '3px' }}
    />
  </Box>
)

const Viewer = ({ imgKey, onChangeKey, show, onDoubleClick }) => {
  const [images, setImages] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const [error, setError] = useState(false)
  const [, setKeyTimeout] = useState()
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [touchTimer, setTouchTimer] = useState()
  const [fileName, setFileName] = useState()

  const startKeyTimeout = useCallback(() => {
    setKeyTimeout((prevValue) => {
      if (prevValue) {
        clearTimeout(prevValue)
      }
      return setTimeout(() => {
        console.log('clear')
        onChangeKey(undefined)
        setImages([])
      }, SLEEP_LIMIT)
    })
  }, [onChangeKey])

  async function unzipBlobToString(input) {
    const reader = new FileReader()

    const result = new Promise((resolve, reject) => {
      reader.onload = () => {
        const data = reader.result
        const unzipped = inflate(data, { to: 'string' })
        resolve(unzipped)
      }
    })
    reader.readAsArrayBuffer(input)
    return result
  }

  const fetchData = useCallback(async () => {
    setLoading(true)
    const { data, headers } = await axios.get('/.netlify/functions/store', {
      responseType: 'json',
    })

    setFileName(headers['content-disposition'].split('=')[1])

    const imgData = await axios({
      method: 'get',
      url: data.url,
      responseType: 'blob',
      onDownloadProgress: (progressEvent) => {
        setProgress(
          Math.round((progressEvent.loaded * 100) / progressEvent.total),
        )
      },
    })

    const inflatedBuffer = await unzipBlobToString(imgData.data)
    const inflatedData = inflatedBuffer.toString('utf-8')

    try {
      const bytes = AES.decrypt(inflatedData, imgKey)
      const body = Buffer.from(bytes.toString(enc.Utf8), 'base64')
      setLoading(false)
      setImages((prevValue) => {
        const newImages = [...prevValue]
        newImages.push(body.toString('base64'))
        return newImages
      })
    } catch (e) {
      console.log({ e })
      setLoading(false)
    }
  }, [imgKey])

  const goNext = useCallback(() => {
    setError(false)
    if (currentIndex < images.length - 1) {
      setCurrentIndex(currentIndex + 1)
      return
    }
    setProgress(0)
    fetchData()
    startKeyTimeout()
  }, [currentIndex, images.length, fetchData, startKeyTimeout])

  const goPrev = useCallback(() => {
    setError(false)
    setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : 0)
    startKeyTimeout()
  }, [currentIndex, startKeyTimeout])

  const onClick = useCallback(
    (event) => {
      const { innerWidth } = window
      if (event.pageX > innerWidth / 2) {
        goNext()
        return
      }
      goPrev()
    },
    [goNext, goPrev],
  )

  const onKeyDown = useCallback(
    ({ key }) => {
      if (key === 'ArrowRight' && imgKey) {
        goNext()
      }
      if (key === 'ArrowLeft') {
        goPrev()
      }
    },
    [imgKey, goNext, goPrev],
  )

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)
    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])

  useEffect(() => {
    if (images.length > 0) {
      setCurrentIndex(images.length - 1)
      return
    }

    if (show) {
      fetchData()
      startKeyTimeout()
    }
  }, [images, show, fetchData, startKeyTimeout])

  const onDelete = useCallback(async () => {
    if (window.confirm('Are you sure')) {
      await axios.post('/.netlify/functions/delete', {
        data: { fileName },
      })
      goNext()
    }
  }, [fileName, goNext])

  const imgData = useMemo(() => images[currentIndex], [images, currentIndex])

  return (
    <Box
      style={{ display: show ? 'block' : 'none', height: '100%' }}
      onDoubleClick={onDoubleClick}
    >
      {loading && <Loading progress={progress} />}
      {error && (
        <Box
          my={20}
          textAlign="center"
          css={{ position: 'absolute', left: 0, right: 0 }}
          zIndex={10}
        >
          Error!
        </Box>
      )}
      {imgData && (
        <Box
          style={{
            display: 'grid',
            width: '100%',
            height: '100%',
            backgroundColor: '#000000',
          }}
          onClick={onClick}
          onTouchStart={() => {
            setTouchTimer(setTimeout(() => onDelete(), 2000))
          }}
          onTouchEnd={() => {
            clearTimeout(touchTimer)
          }}
          onContextMenu={(event) => event.preventDefault()}
        >
          <img
            style={{
              width: '100%',
              height: '100%',
              maxWidth: '100vw',
              maxHeight: '100vh',
              margin: 'auto',
              objectFit: 'contain',
              // opacity: 0.01,
            }}
            src={`data:image/jpeg;base64,${imgData}`}
            alt=""
          />
        </Box>
      )}
    </Box>
  )
}

const MainContent = ({ onChangeKey, show, onDoubleClick }) => {
  const inputRef = useRef(null)

  return (
    <Box
      width="100%"
      maxWidth={960}
      m="auto"
      id="main"
      py={4}
      px={2}
      style={{ display: show ? 'block' : 'none' }}
      onDoubleClick={onDoubleClick}
    >
      <h1 mb={2}>Big O notation</h1>
      <p>
        Big O notation is a mathematical notation that describes the limiting
        behavior of a function when the argument tends towards a particular
        value or infinity. Big O is a member of a family of notations invented
        by Paul Bachmann,[1] Edmund Landau,[2] and others, collectively called
        Bachmann–Landau notation or asymptotic notation.
      </p>
      <p>
        In computer science, big O notation is used to classify algorithms
        according to how their run time or space requirements grow as the input
        size grows.[3] In analytic number theory, big O notation is often used
        to express a bound on the difference between an arithmetical function
        and a better understood approximation; a famous example of such a
        difference is the remainder term in the prime number theorem. Big O
        notation is also used in many other fields to provide similar estimates.
      </p>
      <form
        onSubmit={(event) => {
          event.preventDefault()
          onChangeKey(inputRef.current.value)
          inputRef.current.value = ''
        }}
      >
        <input ref={inputRef} type="password" />
      </form>
    </Box>
  )
}

const IndexPage = () => {
  const [imgKey, setImgKey] = useState('')
  const [viewer, setViewer] = useState(false)

  const onKeyDown = useCallback(
    ({ key }) => {
      if (key === 'Escape' && imgKey) {
        setViewer((prevViewer) => !prevViewer)
      }
    },
    [imgKey],
  )

  const onDoubleClick = useCallback(() => {
    if (imgKey) {
      setViewer((prevViewer) => !prevViewer)
    }
  }, [imgKey])

  useEffect(() => {
    if (imgKey === undefined && viewer) {
      setViewer(false)
    }
  }, [imgKey, viewer])

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)
    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])

  return (
    <>
      <Global
        styles={css`
          * {
            box-sizing: border-box;
            margin: 0;
          }

          html,
          body {
            margin: 0;
            width: 100%;
            height: 100%;
          }

          h1 {
            margin-bottom: 30px;
          }

          p {
            margin-bottom: 20px;
          }
        `}
      />
      <main style={{ height: '100vh' }}>
        <title>BeastRegards</title>
        <Viewer
          imgKey={imgKey}
          onChangeKey={setImgKey}
          show={viewer}
          onDoubleClick={onDoubleClick}
        />
        <MainContent
          show={!viewer}
          onChangeKey={setImgKey}
          onDoubleClick={onDoubleClick}
        />
      </main>
    </>
  )
}

export default IndexPage
